import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Img from "gatsby-image";
// import MiscSocialSmall from "./../icons/MiscSocialSmall";
import { H5SmallMedium, H1BigEmphasis, H5BigMedium } from "./../Typography";
import { HeroProgrammeDiv } from "./elements";

const ProgrammeHero = ({
  topline,
  mobilebanner,
  titleImage,
  titleText,
  subTitleText,
  color,
  titleColor,
  backgroundColor,
  backgroundImage,
  slug,
  isBanner,
  hideDesktopTitle,
  hideMobileTitle
}) => {
  // console.log(backgroundImage, titleImage);

  const dc=(mobilebanner!=null?"desktop-programme-banner":"");
  const mc=(mobilebanner!=null?"mobile-programme-banner":"");


  
  return (
    <HeroProgrammeDiv
      color={color}
      backgroundColor={backgroundColor}
      titleColor={titleColor}
      isBanner={isBanner}
    >
      <div className={`${dc}`}>
      {backgroundImage ? (
        backgroundImage.wideImage.childImageSharp ? (
          <Img
            fixed={backgroundImage.wideImage.childImageSharp.fixed}
            alt={titleText}
          />
        ) : (
          <img src={backgroundImage.wideImage.publicURL} alt={titleText} />
        )
      ) : null}
      <div className="text">
        {/*<MiscSocialSmall color={color} />*/}
        <H5SmallMedium>{topline}</H5SmallMedium>
        
        
        {titleImage ? (
          titleImage.wideImage.childImageSharp ? (
            slug ? (
              <Link to={`/programme/${slug}/`} className="titleimage">
                <Img
                  fixed={titleImage.wideImage.childImageSharp.fixed}
                  alt={titleText}
                />
              </Link>
            ) : (
              <a
                className="titleimage"
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <Img
                  fixed={titleImage.wideImage.childImageSharp.fixed}
                  alt={titleText}
                />
              </a>
            )
          ) : slug ? (
            <Link to={`/programme/${slug}/`} className="titleimage">
              <img src={titleImage.wideImage.publicURL} alt={titleText} />
            </Link>
          ) : (
            <a
              className="titleimage"
              href="/#"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <img src={titleImage.wideImage.publicURL} alt={titleText} />
            </a>
          )
        ) : (
          <div className="text">
            <H1BigEmphasis>
              {slug ? (
                <Link to={`/programme/${slug}/`}>{titleText}</Link>
              ) : (
                <a
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{display:(hideDesktopTitle=='yes'?'none':'block')}}
              >
                {titleText}
              </a>

                
              )}
            </H1BigEmphasis>
            <H5BigMedium>{subTitleText}</H5BigMedium>
          </div>
        )}
      </div>
      </div>
      <div className={`${mc}`}>
      {mobilebanner ? (
        mobilebanner.tallImage.childImageSharp ? (
          <Img
            fixed={mobilebanner.tallImage.childImageSharp.fixed}
            alt={titleText}
          />
        ) : (
          <img src={mobilebanner.tallImage.publicURL} alt={titleText} />
        )
      ) : null} 
      <div className="text">
        {/*<MiscSocialSmall color={color} />*/}
        <H5SmallMedium >{topline}</H5SmallMedium>
        
        
        {titleImage ? (
          titleImage.wideImage.childImageSharp ? (
            slug ? (
              <Link to={`/programme/${slug}/`} className="titleimage">
                <Img
                  fixed={titleImage.wideImage.childImageSharp.fixed}
                  alt={titleText}
                />
              </Link>
            ) : (
              <a
                className="titleimage"
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                }}
                style={{display:(hideMobileTitle=='yes'?'none':'block')}}
              >
                <Img
                  fixed={titleImage.wideImage.childImageSharp.fixed}
                  alt={titleText}
                />
              </a>
            )
          ) : slug ? (
            <Link to={`/programme/${slug}/`} className="titleimage">
              <img src={titleImage.wideImage.publicURL} alt={titleText} />
            </Link>
          ) : (
            <a
              className="titleimage"
              href="/#"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <img src={titleImage.wideImage.publicURL} alt={titleText} />
            </a>
          )
        ) : (
          <div className="text">
            <H1BigEmphasis>
              {slug ? (
                <Link to={`/programme/${slug}/`}>{titleText}</Link>
              ) : (
                <a
                  href="/#"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  
                >
                  {titleText}
                </a>
              )}
            </H1BigEmphasis>
            <H5BigMedium>{subTitleText}</H5BigMedium>
          </div>
        )}
      </div>
      </div>
      
    </HeroProgrammeDiv>
  );
};
export default ProgrammeHero;

ProgrammeHero.propTypes = {
  topline: PropTypes.string,
  titleImage: PropTypes.object,
  mobilebanner: PropTypes.object,
  titleText: PropTypes.string,
  subTitleText: PropTypes.string,
  color: PropTypes.string,
  titleColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.object,
};

ProgrammeHero.defaultProps = {
  color: "var(--black)",
  titleColor: "var(--white)",
  backgroundColor: "var(--green)",
};
