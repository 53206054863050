import React from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import Layout from "./../components/Layout";
import ArtistTakeover from "./../components/ArtistTakeover";
import Perspectives from "./../components/Perspectives";
import ProgrammeHero from "./../components/ProgrammeHero";
import ProgrammeBody from "./../components/ProgrammeBody";
import { TodayOnTheAvenueBlock } from "./../components/RecommendationBlock";
import HomeHeroNew from "./../components/HomeHeroNew";
import ArticleFilter from "./../components/ArticleFilter";
import { CommunityCardHolderDiv, CommunityCard, OverlayDiv } from "./../components/CommunityCardHolder/elements";
import Masonry from "react-masonry-css";
import Img from "gatsby-image";
import { H5Medium, H3Strong } from "./../components/Typography";
import { Link } from "gatsby";
import { BigButton } from "./../components/Buttons";
import GlobalContext from "../components/GlobalContext";
import ContentTypeSelector from "../components/ContentTypeSelector";
import EditorialFilter from "../components/EditorialFilter";
import EventGrid from "../components/EventGrid";
import { ArticleFilterDiv } from "../components/ArticleFilter/elements";
import HomeAvenue from "../components/HomeAvenue";
import styled from "styled-components";

const H3Spotlight =styled.h3`
font-weight: 600;
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: 0.32px;
  margin: 0;
  position:relative;
  @media (max-width: 767px) {
    margin: 0px 25px 25px;
    text-align: center;
  }
`;

const IntroContent =styled.div`
    max-width: var(--width);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--accentColor);
    padding: 0 var(--outerMargin) 25px var(--outerMargin);
    height: 100%;
    padding-bottom: 25px;
    padding-top: 25px;
    z-index: initial;
    position: relative;
    @media (max-width: 767px) {
      padding: 0px 10px 0px 10px;
    }
`;

const SpotLightBtn=styled.div`
text-align:center;
position:relative;
height:100px;
margin-bottom:25px;
@media (max-width: 767px) {
  height:60px;
}
`;

const perspectiveOnTop = false;

const FrontPage = () => {
  const { useStaging } = false;
  const [artistTakeover, setArtistTakeover] = React.useState(null);
  const [artistTakeoverIsOn, setArtistTakeoverIsOn] = React.useState(false);
  const frontQuery = useStaticQuery(graphql`
    query {
      frontPage: craftFrontPageFrontPageEntry {
        frontPageCurrentlyOnTheAvenue {
          ... on Craft_event_event_Entry {
            id
            title
            eventEndDateTime
            eventStartDateTime
            eventShortDescription
            slug
            eventType
            eventLocation {
              title
              slug
            }
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 290) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
        featuredCommunities {
          ... on Craft_community_communityMember_Entry {
            id
            title
            slug
            communityMemberSlug
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 295) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
        perspectives {
          slug
          title
          typeHandle
          ... on Craft_articles_words_Entry {
            editorialAuthor
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_videos_Entry {
            editorialAuthor
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_images_Entry {
            editorialAuthor
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_articles_sounds_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
          ... on Craft_event_event_Entry {
            headerImage {
              url
              ... on Craft_editorialAssets_Asset {
                cardImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 400, height: 230) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
        subtitle
        bannerSelector {
          ... on Craft_articles_videos_Entry {
            bannerCallToAction
            bannerColor
            openExternalLink
            externalLink
            bannerGraphicPortrait {
              ... on Craft_editorialAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            bannerGraphic {
              ... on Craft_editorialAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            bannerTopline
            bannerTitle
            bannerSubtitleLine2
            bannerSubtitleLine1
          }
          ... on Craft_programmes_programmes_Entry {
            bannerCallToAction
            bannerColor
            openExternalLink
            externalLink
            bannerGraphicPortrait {
              ... on Craft_eventsAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            bannerGraphic {
              ... on Craft_eventsAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            bannerTopline
            bannerTitle
            bannerSubtitleLine2
            bannerSubtitleLine1
          }
          ... on Craft_articles_images_Entry {
            bannerCallToAction
            bannerColor
            openExternalLink
            externalLink
            bannerGraphicPortrait {
              id
            }
            bannerGraphicPortrait {
              ... on Craft_eventsAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            bannerGraphic {
              ... on Craft_editorialAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            bannerTopline
            bannerTitle
            bannerSubtitleLine2
            bannerSubtitleLine1
          }
          ... on Craft_articles_sounds_Entry {
            bannerCallToAction
            bannerColor
            openExternalLink
            externalLink
            bannerGraphicPortrait {
              ... on Craft_eventsAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            bannerGraphic {
              ... on Craft_editorialAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            bannerTopline
            bannerTitle
            bannerSubtitleLine2
            bannerSubtitleLine1
          }
          ... on Craft_articles_words_Entry {
            bannerCallToAction
            bannerColor
            openExternalLink
            externalLink
            bannerGraphicPortrait {
              ... on Craft_eventsAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            bannerGraphic {
              ... on Craft_editorialAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            bannerTopline
            bannerTitle
            bannerSubtitleLine2
            bannerSubtitleLine1
          }
          ... on Craft_event_event_Entry {
            bannerCallToAction
            bannerColor
            openExternalLink
            externalLink
            bannerGraphicPortrait {
              ... on Craft_eventsAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                tallImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 800) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            bannerGraphic {
              ... on Craft_eventsAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 1440) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            bannerTopline
            bannerTitle
            bannerSubtitleLine2
            bannerSubtitleLine1
          }
          slug
          typeHandle
        }
        eventFrontPageProgramme {
          ... on Craft_programmes_programmes_Entry {
            id
            title
            programmeBackgroundGraphic {
              ... on Craft_eventsAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(fit: COVER, width: 1440, height: 610) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            programmeHeadline
            programmeLogoGraphic {
              ... on Craft_eventsAssets_Asset {
                wideImage: localFile {
                  publicURL
                  childImageSharp {
                    fixed(fit: CONTAIN, width: 1440, height: 610) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            topline
            slug
          }
        }
      }
      sounds: allCraftEntryInterface(
        filter: { typeHandle: { eq: "sounds" } }
        sort: { fields: dateCreated, order: DESC }
        limit:9
      ) {
        nodes {
          ... on Craft_articles_sounds_Entry {
            id
            title
            slug
            isStaging
            typeHandle
            editorialType
            editorialShortDescription
            hideInPerspectivesPage
            listingImage {
              url
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                     fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      words: allCraftEntryInterface(
        filter: { typeHandle: { eq: "words" } }
        sort: { fields: dateCreated, order: DESC }
        limit:9
      ) {
        nodes {
          ... on Craft_articles_words_Entry {
            id
            title
            slug
            isStaging
            typeHandle
            editorialType
            editorialShortDescription
            hideInPerspectivesPage
            listingImage {
              url
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                     fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                     fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      video: allCraftEntryInterface(
        filter: { typeHandle: { eq: "videos" } }
        sort: { fields: dateCreated, order: DESC }
        limit:9
      ) {
        nodes {
          ... on Craft_articles_videos_Entry {
            id
            title
            slug
            isStaging
            typeHandle
            editorialType
            editorialShortDescription
            hideInPerspectivesPage
            dateCreated
            listingImage {
              url
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                     fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                     fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      images: allCraftEntryInterface(
        filter: { typeHandle: { eq: "images" } }
        sort: { fields: dateCreated, order: DESC }
        limit:9
      ) {
        nodes {
          ... on Craft_articles_images_Entry {
            id
            title
            slug
            isStaging
            typeHandle
            editorialType
            editorialShortDescription
            hideInPerspectivesPage
            listingImage {
              url
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                     fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                     fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            headerImage {
              ... on Craft_editorialAssets_Asset {
                card: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 190, width: 190, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
      events: allCraftEventEventEntry(filter: { isStaging: { eq: false } }) {
        nodes {
          id
          title
          isStaging
          eventEndDateTime
          eventStartDateTime
          eventShortDescription
          slug
          eventType
          eventLocation {
            title
            slug
          }
          listingImage {
            url
            ... on Craft_editorialAssets_Asset {
              card: localFile {
                publicURL
                childImageSharp {
                   fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            ... on Craft_communityAssets_Asset {
              card: localFile {
                publicURL
                childImageSharp {
                   fixed(height: 190, width: 190, fit: COVER) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          headerImage {
            url
            ... on Craft_editorialAssets_Asset {
              cardImage: localFile {
                publicURL
                childImageSharp {
                  fixed(width: 290) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const contentTypeColor = {
    words: "var(--green)",
    sounds: "var(--red)",
    images: "var(--purple)",
    videos: "var(--yellow)",
  };
  const contentTypeIndex = { words: 0, sounds: 1, images: 2, videos: 3 };
  const thisProgramme = frontQuery.frontPage.eventFrontPageProgramme.length
  ? frontQuery.frontPage.eventFrontPageProgramme[0]
  : null;
const thisPerpectives = frontQuery.frontPage.perspectives.length
  ? frontQuery.frontPage.perspectives
  : null;
const featuredCommunities = frontQuery.frontPage.featuredCommunities.length
  ? frontQuery.frontPage.featuredCommunities : null;
const thisBanner = frontQuery.frontPage.bannerSelector || null;
const intro=frontQuery.frontPage.subtitle || null;

// console.log(thisPerpectives.map((x) => x.headerImage[0].cardImage));
// console.log(thisBanner);
// const todaysEventsOld = frontQuery.events.nodes.slice(0, 8); //TODO: Filter this so it's only today's events!
const todaysEvents = frontQuery.frontPage.frontPageCurrentlyOnTheAvenue;
// console.log(todaysEvents);
const unfilteredEverything = [
  ...frontQuery.images.nodes,
  ...frontQuery.sounds.nodes,
  ...frontQuery.video.nodes,
  ...frontQuery.words.nodes,
].filter(useStaging ? (x) => x : (x) => !x.isStaging);
const everything = unfilteredEverything.filter((x) => {
  const a = (x.hideInPerspectivesPage
    && x.hideInPerspectivesPage
    && x.hideInPerspectivesPage == 'yes' ? 'hide' : 'show')
  return (a == 'show');
});
const [shownArticles, setShownArticles] = React.useState(everything);
const [
  currentArticleTypeHandle,
  setCurrentArticleTypeHandle,
] = React.useState("words");
React.useEffect(() => {
  // This is in here because the content seletor doesn't have an unset state!
  // this should only run first time this is rendered!
  const newSet = everything.filter(
    (x) => x.typeHandle === currentArticleTypeHandle
  );
  setShownArticles(newSet);
}, []);
const backgroundColor = "var(--white)"
const textColor =
  backgroundColor === "var(--black)" ? "var(--white)" : "var(--black)";
return (
  <Layout
    backgroundColor={"var(--white)"}
    skipHeader
    isFixed={artistTakeoverIsOn}
  >
    {thisBanner && !perspectiveOnTop ? (
      <HomeHeroNew
        heros={thisBanner}
        onCallToAction={(e) => {
          if (e.indexOf(`/artistTakeover/`) > -1) {
            const theSlug = e.split("/artistTakeover/")[1].split("/")[0];
            if (thisBanner.filter((x) => x.slug === theSlug).length) {
              const thisArtistTakeover = thisBanner.filter(
                (x) => x.slug === theSlug
              )[0];
              // console.log(thisArtistTakeover);
              setArtistTakeoverIsOn(true);
              setArtistTakeover(thisArtistTakeover);
            }
          } else {
            navigate(e);
          }
        }}
      />
    ) : null}
    
      <IntroContent>
      <p
        dangerouslySetInnerHTML={{__html: intro}}
        style={{margin: '0px',
          fontWeight: '400',
          fontSize: '24px',
          lineHheight: '36px',
          letterSpacing: '0.32px',
          fontFamily:'Euclid Circular A'}}
      />
      </IntroContent>
      <ProgrammeBody accentColor={"var(--white)"} eventsPage={false}>
      <div style={{ marginTop: '50px' }}>
      <HomeAvenue  backGroundColor={"var(--white)"} backgroundColor={"var(--white)"} titleColor={"var(--black)"} style={{backgroundColor:'var(--accentColor)'}} tiles={todaysEvents} onTop={perspectiveOnTop}/>
      </div>
      
      </ProgrammeBody>
    <ProgrammeBody accentColor={"var(--red)"} eventsPage={false}>
      <div style={{ marginTop: '50px' }}>
          <H3Spotlight style={{ fontWeight: 'normal',color:'var(--white)' }}>COMMUNITY SPOTLIGHT</H3Spotlight>
          <CommunityCardHolderDiv style={{ width: "100%" }}>
            <Masonry
              breakpointCols={{ default: 4, 1270: 3, 950: 2, 635: 1 }}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {featuredCommunities.map((card, index) => {
                return (
                  <CommunityCard
                    key={index}
                  >
                    <Link to={`/community/${card.communityMemberSlug}/`}>
                      {card.headerImage.length &&
                        card.headerImage[0] &&
                        card.headerImage[0].wideImage &&
                        card.headerImage[0].wideImage.childImageSharp ? (
                        <div id={card.slug.replace(/\d/g, "")}>
                          <Img
                            fixed={
                              card.headerImage[0].wideImage.childImageSharp.fixed
                            }
                          />
                        </div>
                      ) : card.headerImage.length &&
                        card.headerImage[0] &&
                        card.headerImage[0].wideImage ? (
                        <img
                          src={card.headerImage[0].wideImage.publicURL}
                          id={card.slug.replace(/\d/g, "")}
                        />
                      ) : (
                        <div
                          className="placeholder"
                          id={card.slug.replace(/\d/g, "")}
                        />
                      )}
                      <H5Medium style={{color:'#ffffff'}}>{card.title}</H5Medium>
                    </Link>
                  </CommunityCard>
                );
              })}
            </Masonry>
          </CommunityCardHolderDiv>
          <SpotLightBtn>
            <Link to={`/community/`}>
                <BigButton color={"var(--white)"} style={{fontStyle:'unset'}}>Discover the Avenue</BigButton>
              </Link>
          </SpotLightBtn>
          

        </div>
      </ProgrammeBody>
      <ProgrammeBody>
      {thisProgramme ? (
          <ProgrammeHero
            isBanner
            slug={thisProgramme.slug}
            topline={thisProgramme.topline}
            titleText={thisProgramme.title}
            subTitleText={thisProgramme.programmeHeadline}
            backgroundColor={"var(--green)"}
            color={"var(--white)"}
            titleColor={"var(--black)"}
            backgroundImage={
              thisProgramme.programmeBackgroundGraphic.length
                ? thisProgramme.programmeBackgroundGraphic[0]
                : null
            }
            titleImage={
              thisProgramme.programmeLogoGraphic.length
                ? thisProgramme.programmeLogoGraphic[0] 
                : null
            }
          />
        ) : null}
        
        {/* <TodayOnTheAvenueBlock events={todaysEvents} color={"var(--black)"} accentColor={"var(--white)"} btnColor={"var(--red)"}/> */}
        <Perspectives tiles={thisPerpectives} title={`THROUGH THE LENS`} onTop={true} />
        
        
      
      </ProgrammeBody>
      
      
  </Layout> 
);
  
};

export default FrontPage;
